import {Form1099Type} from "@/constants/PayrollConstants";
import {CounterpartyType} from "@/constants/CounterpartyType";

export class Upload1099FormPayload {

    contractorType: CounterpartyType | null = null;
    contractorId: number | null = null;
    type!: Form1099Type;
    year!: number;
    fileId!: number;
    contractorName: string | null = null;
    companyId: number | null = null;

    constructor(init?: Partial<Upload1099FormPayload>) {
        Object.assign(this, init);
    }
}