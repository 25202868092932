export enum Form1099Type {
    FORM_1099_MISC = "FORM_1099_MISC",
    FORM_1099_NEC = "FORM_1099_NEC",
    FORM_1099_G = "FORM_1099_G",
    FORM_1099_INT = "FORM_1099_INT",
    FORM_1099_DIV = "FORM_1099_DIV",
    FORM_1099_SSA = "FORM_1099_SSA",
    FORM_1099_R = "FORM_1099_R",
    FORM_1099_B = "FORM_1099_B",
    FORM_1099_K = "FORM_1099_K",
}